(function($) {
    /**  Form Submissions
    *********************/
  
    // Caldera Forms
    /*
    jQuery( document ).on( 'cf.form.submit', function ( event, data ) {
        // Send GA Event
        console.log('Form Submitted.');
        gtag('event', 'form_submit');
    });
    */
    
    /**  Button w/ ID
    *********************/
    $("#clickCall").click(function() {
      console.log('Click to Call.');
      gtag('event', 'click_call');
    });
    
  })(jQuery);